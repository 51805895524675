





































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import {
  EditIcon,
  TrashIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DownloadIcon
} from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import Modal from "../components/ui/Modal.vue";
import NoData from "../components/ui/No-Data.vue";
import Search from "../components/ui/Search.vue";
import DaterangePicker from "../components/ui/Daterange-Picker.vue";
import moment from "moment";

import XLSX from "xlsx";

@Component({
  components: {
    EditIcon,
    TrashIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    Loader,
    Modal,
    NoData,
    Search,
    DaterangePicker,
    DownloadIcon,
  },
})
export default class Assets extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */

  assetsTotalPages = 1;
  assetsCurrentPage = 1;
  assetsStartIndex = 0;
  assetsEndIndex = 0;
  assetsLength = 0;
  assetsList = [];
  isLoading = false;
  showDeleteModal = false;
  showNoData = false;
  noDataType = "";

  mounted() {
    setTimeout(() => {
      this.fetchAssetsList("", "", "");
    }, 0);
  }

  lastQueryStartDate = "";
  lastQueryEndDate = "";

  onDateRangeChange(dateRange) {
    this.lastQueryStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    this.lastQueryEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    this.fetchAssetsList(
      this.lastQuerySearchText,
      moment(dateRange.startDate).format("YYYY-MM-DD"),
      moment(dateRange.endDate).format("YYYY-MM-DD")
    );
  }
  lastQuerySearchText = "";
  onSearchSubmit(seachText) {
    this.lastQuerySearchText = seachText;
    this.fetchAssetsList(
      seachText,
      this.lastQueryStartDate,
      this.lastQueryEndDate
    );
  }

  filterType = "nofilter";

  onReloadData() {
    this.filterType = "nofilter";
    this.lastQueryStartDate = "";
    this.lastQueryEndDate = "";
    setTimeout(() => {
      this.fetchAssetsList("", "", "");
    }, 0);
  }

  onGenerateReport() {
    if (this.assetsList.length > 0) {
      const data = XLSX.utils.json_to_sheet(
        this.assetsList.map((x: any) => ({
          brand: x.brand,
          model: x.model,
          tag_id: x.tag_id,
          serial_no: x.serial_no,
          category: x.category,
          yom: x.yom,
          created_on: x.created_on,
        }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "asset.xlsx");
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Failed!",
        text: "No Asset found",
        type: "Danger",
      });
    }
  }

  async onGenerateMISReport() {
    await fetch(`${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list/pdf`, {
      method: "GET",
      credentials: "include"
    })
      .then(res => res.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download =
          "assets-report-" +
          new Date(Date.now()).toLocaleDateString().replaceAll("/", "-") +
          ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  async fetchAssetsList(searchText, startDate, endDate) {
    try {
      this.isLoading = true;
      this.showNoData = false;

      let url = `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list`;

      if (searchText) {
        if (startDate && endDate) {
          if (this.filterType != "nofilter") {
            if (startDate === endDate) {
              url += `?search=${searchText}&fixedDate=${startDate}&${this.filterType}=${searchText}`;
            } else {
              url += `?search=${searchText}&startDate=${startDate}&endDate=${endDate}&${this.filterType}=${searchText}`;
            }
          } else {
            if (startDate === endDate) {
              url += `?search=${searchText}&fixedDate=${startDate}`;
            } else {
              url += `?search=${searchText}&startDate=${startDate}&endDate=${endDate}`;
            }
          }
        } else {
          if (this.filterType != "nofilter") {
            url += `?search=${searchText}&${this.filterType}=${searchText}`;
          }
        }
      } else {
        if (startDate && endDate) {
          if (startDate === endDate) {
            url += `?fixedDate=${startDate}`;
          } else {
            url += `?startDate=${startDate}&endDate=${endDate}`;
          }
        }
      }

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
      });

      if (response.status == 200) {
        this.assetsList = await response.json();
        if (this.assetsList) {
          this.assetsList = [...this.assetsList.reverse()];
        }
        this.assetsLength = this.assetsList.length;
        this.initPaginations(this.assetsLength);

        if (this.assetsList.length === 0) {
          this.noDataType = "Empty";
          this.showNoData = true;
        }
      } else {
        this.noDataType = "Error";
        this.showNoData = true;
      }
    } catch (e) {
      this.noDataType = "Error";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }

  initPaginations(length: number) {
    this.assetsEndIndex = length > 44 ? 44 : length;
    this.assetsTotalPages =
      this.assetsList.length % 44 === 0
        ? Math.floor(this.assetsList.length / 44)
        : Math.floor(this.assetsList.length / 44 + 1);
  }

  onNextPagination() {
    if (this.assetsCurrentPage >= this.assetsTotalPages) return;

    this.assetsCurrentPage += 1;
    this.assetsStartIndex = this.assetsEndIndex;
    this.assetsEndIndex =
      this.assetsList.length > this.assetsStartIndex + 44
        ? this.assetsStartIndex + 44
        : this.assetsList.length;
  }

  onPrevPagination() {
    if (this.assetsCurrentPage <= 1) return;

    this.assetsCurrentPage -= 1;
    this.assetsEndIndex = this.assetsStartIndex;
    this.assetsStartIndex =
      this.assetsStartIndex - 44 <= 0 ? 0 : this.assetsStartIndex - 44;
  }

  onShowDeleteModal(id: string) {
    this.showDeleteModal = true;
    this.idToBeDeleted = id;
  }

  idToBeDeleted = "";
  async onDeleteAsset() {
    if (this.idToBeDeleted !== "") {
      try {
        const response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/${this.idToBeDeleted}`,
          {
            method: "DELETE",
            credentials: "include",
          }
        );

        if (response.status === 200) {
          this.$store.commit("showToastMessage", {
            boldText: "Successfully!",
            text: "Asset deleted",
            type: "Success",
          });
          this.onReloadData();
        } else {
          this.$store.commit("showToastMessage", {
            boldText: "Failed!",
            text: "Something went wrong",
            type: "Danger",
          });
        }
      } catch (e) {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: e.message,
          type: "Danger",
        });
      } finally {
        this.onHideDeleteModal();
      }
    } else {
      this.onHideDeleteModal();
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "Invalid id selected",
        type: "Danger",
      });
    }
  }

  onHideDeleteModal() {
    this.showDeleteModal = false;
  }
}
